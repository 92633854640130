import "./stylesCardH.css"
import { Link } from 'react-router-dom';
import { urlLocal, url } from '../API_URL/ApiUrl';


export const CardH = (props) => {
    return (
        <button onClick={props.upd} className='containerCardH' style={{cursor: "pointer"}}>
            <div className='cardH'>
                <div className='cardImg1'>
                    <img src={`${urlLocal}/image/${props.images}`} alt="img" width="100%" height="100%"/>
                </div>
                <div className='cardhBody'>
                    <p className='cardTitle'>{props.title}</p>
                    <span className='cardDetail'>
                        {props.desc}
                    </span>
                    <Link to={"/materiel"} className='cardBtn'>Tout voir</Link>
                </div>
            </div>
        </button>
    );
};

export const CardH1 = (props) => {
    return (
        <button onClick={props.upd} className='containerCardAdmin' style={{cursor: "pointer"}}>
            <div className='cardH'>
                <div className='cardImg1'>
                    <img src={`${urlLocal}/image/${props.images}` } alt="img" width="100%" height="100%"/>
                </div>
                <div className='cardhBody'>
                    <p className='cardTitle'>{props.title}</p>
                    <span className='cardDetail'>
                        {props.desc}
                    </span>
                    <Link to={"/materiel"} className='cardBtn'>Tout voir</Link>
                </div>
            </div>
        </button>
    );
};


export const Card1 = (props) => {
    return (
        <button onClick={props.upd} className='containerCardH1'>
            <div className='cardH2'>
                <div className='cardImg1'>
                    <img src={`${urlLocal}/image/${props.images}`} alt="img" width="100%" height="100%"/>
                </div>
                <div className='cardhBody'>
                    <p className='cardTitle'>{props.title}</p>
                    <span className='cardDetail'>
                        {props.desc}
                    </span>
                </div>
            </div>
        </button>
    );
};

export const Card2 = (props) => {
    return (
        <button onClick={props.upd} className='containerCardH2'>
            <div className='cardH1'>
                <div className='cardImg1'>
                    <img src={`${urlLocal}/image/${props.images}`} alt="img" width="100%" height="100%"/>
                </div>
                <div className='cardhBody2'>
                    <button className='cardBtn2'>
                        Voir le groupe
                    </button>
                </div>
            </div>
        </button>
    );
};

export const Card3 = (props) => {
    return (
        <button onClick={props.upd} className='containerCardH1'>
            <div className='cardH3'>
                <div className='cardImg1'>
                    <img src={`${urlLocal}/image/${props.images}` } alt="img" width="100%" height="100%"/>
                </div>
                <div className='cardhBody'>
                    <p className='cardTitle'>{props.title}</p>
                    <span className='cardDetail'>
                        {props.desc}
                    </span>
                </div>
            </div>
        </button>
    );
};


export const CardListMeuble = (props) => {
    return (
        <button onClick={props.upd} className='containerCardMeubleH'>
            <h3>{props.title}</h3>
            <p>
                {props.desc}
            </p>
            <Link className='cardBtn'>Voir toute la liste</Link>
        </button>
    );
};
import { useState, useEffect } from 'react';
import SvgColor from '../../../components/svg-color';
import { apiUserPermission, apiUser, entete, urlLocal } from '../../../components/API_URL/ApiUrl';


export const NavConfig = () => {
  const [permission, setPermission] = useState([]);
  const id = localStorage.getItem('id');

  useEffect(() => {
    const getDataById = async () => {
      const token = localStorage.getItem('token');
      try {
        const { data } = await apiUser.get(`/${id}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        });
        setPermission(data.data.permissions.map(permission => permission.nom_permission));
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
        } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
        }
      }
    };

    getDataById();
  }, [id]);

  return permission.map((nom_permission) => {
    let path = '';
    let icon = (name) => <SvgColor src={`${urlLocal}/image/${name}.svg`} sx={{ width: 1, height: 1 }} />;
  
    if (nom_permission === 'Gestion des Categories') {
      path = '/dashboard/categorie';
      icon = icon('ic_user');
    } else if (nom_permission === 'Gestion des Sous Categories') {
      path = '/dashboard/souscategorie';
      icon = icon('ic_user');
    } else if (nom_permission === 'Gestion des Articles') {
      path = '/dashboard/products';
      icon = icon('ic_cart');
    } else if (nom_permission === 'Gestion des Utilisateurs') {
      path = '/dashboard/user';
      icon = icon('ic_user');
    } else if (nom_permission === 'Gestion du Contenu') {
      path = '/dashboard/contenu';
      icon = icon('ic_analytics');
    } else if (nom_permission === 'Gestion de commandes') {
      path = '/dashboard/commande';
      icon = icon('ic_analytics');
    } else if (nom_permission === 'Gestion de paiement') {
      path = '/dashboard/paiement';
      icon = icon('ic_analytics');
    } else if (nom_permission === 'Rapport') {
      path = '/dashboard/app';
      icon = icon('ic_analytics');
    }
  
    return {
      title: nom_permission === 'Rapport' ? 'Tableau de bord' : nom_permission,
      path,
      icon,
    };
  });
  
};


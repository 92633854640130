import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Select,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  Switch,
  FormControlLabel,
  Divider
} from '@mui/material';
// components
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import Label from '../components/label';
import Iconify from '../components/iconify';
import { AiOutlineClose } from 'react-icons/ai';
import Scrollbar from '../components/scrollbar';
import { apiUser, apiSignUp, entete, apiUserPermission, notify } from '../components/API_URL/ApiUrl';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TABLE_HEAD = [
  { id: 'nom', label: 'Nom', alignRight: false },
  { id: 'prenom', label: 'Prenom', alignRight: false },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'adresse', label: 'Adresse', alignRight: false },
  { id: 'telephone', label: 'Téléphone', alignRight: false },
  { id: 'type', label: 'type', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [state, setState] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const [status, setStatus] = useState();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  // Modal utilisateur
  const [showuser, setShowuser] = useState(false);
  const handleCloseuser = () => setShowuser(false);
  const handleShowuser = () => setShowuser(true);

  // Modal Update User
  const [showupd, setShowupd] = useState(false);
  const handleCloseupd = () => setShowupd(false);
  const handleShowupd = () => setShowupd(true);

  // Data Users
  const [user, setUser] = useState([]);
  const [id_upd, setId_upd] = useState('');
  const [ids, setIds] = useState(0);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [adresse, setAdresse] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('');
  const [permissionID, setPermissionID] = useState([]);

  // Permission
  const [permission, setPermission] = useState([]);

  // Afficher tous les utilisateurs
  const allpermission = async () => {
    const token = localStorage.getItem('token');
    try {
        apiUserPermission.get('/', {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => {
          console.log(res.data.data)
          setPermission(res.data.data);
        })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const handleChange = (permissionId) => {
    if (selectedPermissions.includes(permissionId)) {
      // La permission est déjà dans le tableau, donc on la retire
      setSelectedPermissions(selectedPermissions.filter(id => id !== permissionId));
    } else {
      // La permission n'est pas dans le tableau, donc on l'ajoute
      setSelectedPermissions([...selectedPermissions, permissionId]);
    }
  };

  // Afficher tous les utilisateurs
  const alluser = async () => {
    const token = localStorage.getItem('token');
    try {
      apiSignUp.get('/', {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        console.log(res.data.data)
        setUser(res.data.data);
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  }; 
  
  const ajoutUser = async (e) => {
    e.preventDefault();
    e.persist()
    // console.log(email, password);
    const userId = localStorage.getItem('id');
    const permissionId = selectedPermissions;
    if(nom != "" && prenom != "" && email != "" && type != "" && password != "" && userId != "" && permissionId != "") {
      try {
        await apiUser.post('/', {
          nom,
          prenom,
          adresse,
          email,
          telephone,
          type,
          password,
          userId,
          permissionId
        }).then((res) => {
          setNom("")
          setPrenom("")
          setAdresse("")
          setEmail("")
          setTelephone("")
          setPassword("")
          setType("")
          handleCloseuser()
          setUser([]);
          alluser();
          setStatus(true);
          notify('success', `${res.data.message}`);
        }).catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.message === "L'email existe déjà pour un utilisateur.") {
            notify('warning', "L'email existe déjà pour un utilisateur.");
          } else {
            notify('warning', "Vos informations sont incorrectes");
          }
        })
      } catch (error) { 
        if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
        } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
        }
      }
    } else {
      notify('warning', "Les champs sont vides");
    }
  };

  const getDataById = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await apiUser.get(`/${ids}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      })
      console.log(data);
      console.log(data.data);
      setId_upd(data.data.id)
      setNom(data.data.nom)
      setPrenom(data.data.prenom)
      setAdresse(data.data.adresse)
      setEmail(data.data.email)
      setTelephone(data.data.telephone)
      setPassword(data.data.password)
      setType(data.data.type)
      setPermissionID(data.data.permissions); 
    } catch (error) { 
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  }

  const updateUser = async (e) => {
    // alert(ids)
    e.preventDefault();
    const token = localStorage.getItem('token');
    const permissionId = selectedPermissions;
    try {
      await apiUser.put(`/${ids}`, 
      { 
        nom,
        prenom,
        adresse,
        email,
        telephone,
        type,
        password,
        permissionId
      },
      {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        setNom("")
        setPrenom("")
        setAdresse("")
        setEmail("")
        setTelephone("")
        setPassword("")
        setType("")
        handleCloseupd();
        setUser([]);
        alluser();
        setStatus(true);
        notify('success', `${res.data.message}`);
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // Supprimer un categorie
  const deleteConfirm = (ids) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Confirmer la suppression',
      denyButtonText: 'Non'

    }).then((result) => {
      // Read more about isConfirmed, isDenied below 
      if (result.isConfirmed) {
        deleteArticle(ids);
      } else if (result.isDenied) {
        Swal.fire('Suppression Annulée', '', 'info')
      }
    })
  };

  const deleteArticle = async (ids) => {
    const token = localStorage.getItem('token');
    try {
      apiUser.delete(`/${ids}`, {
        id: ids,
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        // alert(res.data)
        setUser([]);
        alluser();
        notify('success', `${res.data.message}`);
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = user.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, nom) => {
    const selectedIndex = selected.indexOf(nom);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, nom);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - user.length) : 0;

  const filteredUsers = applySortFilter(user, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const rowsPerPageOptions = [5, 10, 25];

  for (let i = 25; i <= user.length; i += 50) {
      rowsPerPageOptions.push(i);
  }

  useEffect(() => {
    alluser();
    allpermission();
  }, []);

  useEffect(() => {
    getDataById();
  }, [ids]);

  return (
    <>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Helmet>
        <title> Gestion des Utilisateurs | AGS </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Gestion des Utilisateurs
          </Typography>
          <Button onClick={handleShowuser} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Ajouter un utilisateur
          </Button>
        </Stack>

        <Card>
          <UserListToolbar label={"Rechercher un utilisateur"} numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={user.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, nom, prenom, email, adresse, telephone, type } = row;
                    const selectedUser = selected.indexOf(nom) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, nom)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={nom} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {nom}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{prenom}</TableCell>

                        <TableCell align="left">{email}</TableCell>
                        
                        <TableCell align="left">{adresse}</TableCell>

                        <TableCell align="left">{telephone}</TableCell>

                        <TableCell align="left">
                          <Label color={(type === 'utilisateur' && 'secondary') || 'success'}>{sentenceCase(type)}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => { handleOpenMenu(event, id); setIds(id) }}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Pas trouvé
                          </Typography>

                          <Typography variant="body2">
                            Aucun résultat trouvé pour &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={user.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => { handleShowupd(); handleCloseMenu()}}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Modifier
        </MenuItem>

        <MenuItem onClick={() => { deleteConfirm(ids); handleCloseMenu() }} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Supprimer
        </MenuItem>
      </Popover>

      {/* MODAL ADD USER */}
      <Modal
        open={showuser}
        onClose={handleCloseuser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div onClick={handleCloseuser}>
              <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
            </div>
            <br/>
          <Stack direction="row" spacing={3}>
            <Stack spacing={3}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Ajouter un utilisateur
              </Typography>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={type} 
                  onChange={(e) => setType(e.target.value)} 
                  label="Type"
                  required
                >
                  <MenuItem value="0">
                    <em>Choisir un Type</em>
                  </MenuItem>
                  <MenuItem value="admin">
                    <em>admin</em>
                  </MenuItem>
                  <MenuItem value="utilisateur">
                    <em>utilisateur</em>
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                name="nom"
                type='text'
                label="Nom"
                variant="standard"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                required
              />
              <TextField
                name="prenom"
                type='text'
                label="Prenom"
                variant="standard"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
                required
              />
              <TextField
                name="adresse"
                type='text'
                label="Adresse"
                variant="standard"
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
                required
              />
              <TextField
                name="email"
                type='email'
                label="Email"
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                name="telephone"
                type='number'
                label="Téléphone"
                variant="standard"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
                required
              />
              <TextField
                name="password"
                type={showPassword ? 'text' : 'password'}
                label="Mot de passe"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={ajoutUser}>
                Ajouter
              </LoadingButton>
            </Stack>
            <Box>
              {permission.map(item => (
                <div key={item.id}>
                  <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={selectedPermissions.includes(item.id)}
                          onChange={() => handleChange(item.id)}
                          name="gilad"
                        />
                      }
                      label={item.nom_permission}
                    />
                  </div>
                </div>
              ))}
            </Box>
          </Stack>
        </Box>
      </Modal>

      {/* MODAL UPDATE USER */}
      <Modal
        open={showupd}
        onClose={handleCloseupd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div onClick={handleCloseupd}>
              <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
            </div>
            <br/>
          <Stack direction="row" spacing={3}>
            <Stack spacing={3}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Modifier un utilisateur
              </Typography>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={type} 
                  onChange={(e) => setType(e.target.value)} 
                  label="Type"
                  required
                >
                  <MenuItem value="0">
                    <em>Choisir un Type</em>
                  </MenuItem>
                  <MenuItem value="admin">
                    <em>admin</em>
                  </MenuItem>
                  <MenuItem value="utilisateur">
                    <em>utilisateur</em>
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                name="nom"
                type='text'
                label="Nom"
                variant="standard"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                required
              />
              <TextField
                name="prenom"
                type='text'
                label="Prenom"
                variant="standard"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
                required
              />
              <TextField
                name="adresse"
                type='text'
                label="Adresse"
                variant="standard"
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
                required
              />
              <TextField
                name="email"
                type='email'
                label="Email"
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                name="telephone"
                type='number'
                label="Téléphone"
                variant="standard"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
                required
              />
              <TextField
                name="password"
                type={showPassword ? 'text' : 'password'}
                label="Mot de passe"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateUser}>
                Modifier
              </LoadingButton>
            </Stack>
            <Box>
              <div style={{width: "100%"}}>
                {nom} {prenom} a les autautisations suivantes:
                {
                  permissionID.map(item => (
                    <li>{item.nom_permission}</li>
                  ))
                }
              </div>
              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Reccréer ses droits d'accès
                </Typography>
              </Divider>
              {permission.map(item => (
                <div key={item.id}>
                  <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={selectedPermissions.includes(item.id)}
                          onChange={() => handleChange(item.id)}
                          name="gilad"
                        />
                      }
                      label={item.nom_permission}
                    />
                  </div>
                </div>
              ))}
            </Box>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

import { urlLocal } from "../components/API_URL/ApiUrl";
// -----------------------------------------------------------------------

const account = {
  displayName: 'Jaydon Frankie',
  email: 'demo@minimals.cc',
  photoURL: `${urlLocal}/Images/assets/images/avatars/avatar_default.jpg`,
};

export default account;

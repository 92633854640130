import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styleContenu.css';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Select,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  Switch,
  FormControlLabel,
  Divider,
  Grid,
  styled
} from '@mui/material';
// components
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import Logo from '../components/assets/AGS_logo_derive.png';
import ProduitEmpty from '../components/assets/produit.jpg';
import { AiOutlineClose } from 'react-icons/ai';
import { Card1, Card2, Card3, CardH1, CardListMeuble } from '../components/Card/CardH';
import { apiApropos, apiContenuImg, entete, entetes, apiUserPermission, notify, apiCategorie, apiContenuSlide, urlLocal } from '../components/API_URL/ApiUrl';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }; 
export default function ContenuPage() {
    const [status, setStatus] = useState();

    // POUR LE CONTENU A PROPOS
    const [ids, setIds] = useState(0);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState([]);

    // POUR LE CONTENU IMAGES
    const [idC, setIdC] = useState(0);
    const [titleSectionImg, setTitleSectionImg] = useState("");
    const [descSectionImg, setDescSectionImg] = useState("");
    const [imgSectionImg, setImgSectionImg] = useState("");
    const [imgSectionImgupd, setImgSectionImgupd] = useState("");

    const [imgSectionImg2, setImgSectionImg2] = useState("");
    const [imgSectionImg2upd, setImgSectionImg2upd] = useState("");

    const [titleSectionImg3, setTitleSectionImg3] = useState("");
    const [descSectionImg3, setDescSectionImg3] = useState("");
    const [imgSectionImg3, setImgSectionImg3] = useState("");
    const [imgSectionImg3upd, setImgSectionImg3upd] = useState("");

    const [titleSectionImg4, setTitleSectionImg4] = useState("");
    const [descSectionImg4, setDescSectionImg4] = useState("");
    
    const [titleSectionImg5, setTitleSectionImg5] = useState("");
    const [descSectionImg5, setDescSectionImg5] = useState("");
    const [imgSectionImg5, setImgSectionImg5] = useState("");
    const [imgSectionImg5upd, setImgSectionImg5upd] = useState("");


    // Modal Update A propos
    const [showupd, setShowupd] = useState(false);
    const handleCloseupd = () => {
        setShowupd(false)
    };
    const handleShowupd = (e) => {
        setShowupd(true)
    };

    /* ***************************************** */
    // Modal Update Contenu
    const [showupdImg, setShowupdImg] = useState(false);
    const handleCloseupdImg = () => {
        setShowupdImg(false)
    };
    const handleShowupdImg = (e) => {
        setShowupdImg(true)
    };
    const [showupdImg2, setShowupdImg2] = useState(false);
    const handleCloseupdImg2 = () => {
        setShowupdImg2(false)
    };
    const handleShowupdImg2 = (e) => {
        setShowupdImg2(true)
    };
    const [showupdImg3, setShowupdImg3] = useState(false);
    const handleCloseupdImg3 = () => {
        setShowupdImg3(false)
    };
    const handleShowupdImg3 = (e) => {
        setShowupdImg3(true)
    };
    const [showupdImg4, setShowupdImg4] = useState(false);
    const handleCloseupdImg4 = () => {
        setShowupdImg4(false)
    };
    const handleShowupdImg4 = (e) => {
        setShowupdImg4(true)
    };
    const [showupdImg5, setShowupdImg5] = useState(false);
    const handleCloseupdImg5 = () => {
        setShowupdImg5(false)
    };
    const handleShowupdImg5 = (e) => {
        setShowupdImg5(true)
    };
    /* ***************************************** */
    // Modal Ajouter Sldie
    const [showaddSlide, setShowaddSlide] = useState(false);
    const handleCloseaddSlide = () => {
        setShowaddSlide(false)
    };
    const handleShowaddSlide = (e) => {
        setShowaddSlide(true)
    };
    const [slide, setSlide] = useState([]);
    const [img_slide, setImg_slide] = useState(null);
    // Data categorie
    const [categorie, setCategorie] = useState([]);
    const [categorieId, setCategorieId] = useState('');

    const [userInfo, setuserInfo] = useState({
        filepreview: null,
    });
    const [telValues, setTelValues] = useState([]);
    const handleCreationObjet = () => {
        setTelValues([...telValues, '']);
    };           

    // Afficher le contenu de A propos
    const allapropos = async () => {
        const token = localStorage.getItem('token');
        try {
            apiApropos.get('/1', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setIds(res.data.data.id);
                setTitle(res.data.data.title);
                setDescription(res.data.data.description);
                setEmail(res.data.data.email);
                setTel(res.data.data.tel);
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
            } 
        }
    };

    // Update de A propos
    const updateApropos = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        try {
            await apiApropos.put(`/${ids}`, 
            { 
                title,
                description,
                email,
                tel: telValues.join('/'), // Regroupe les valeurs en une seule chaîne
            },
            {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                handleCloseupd();
                setTitle("");
                setDescription("");
                setEmail("");
                setTel([]);
                allapropos();
                setStatus(true);
                notify('success', `${res.data.message}`);
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    };

    /* **************************************** */
    // Afficher le contenu
    const allcontenuByImg1 = async () => {
        const token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/1', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setIdC(res.data.data.id)
                setTitleSectionImg(res.data.data.titleSectionImg)
                setDescSectionImg(res.data.data.descSectionImg)
                setImgSectionImg(res.data.data.imgSectionImg)
                setImgSectionImgupd(res.data.data.imgSectionImg)
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
            } 
        }
    };
    // Update de article
    const updateContenuImage = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append("titleSectionImg", titleSectionImg);
        formData.append("descSectionImg", descSectionImg);
        formData.append("imgSectionImg", imgSectionImg);

        await apiContenuImg.put('/1', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                entetes,
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setImgSectionImg("");
            handleCloseupdImg();
            allcontenuByImg1();
            setStatus(true);
            notify('success', `${res.data.message}`);
        })
    };
    const allcontenuByImg2 = async () => {
        const token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/2', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setImgSectionImg2(res.data.data.imgSectionImg)
                setImgSectionImg2upd(res.data.data.imgSectionImg)
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
            } 
        }
    };
    const updateContenuImage2 = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append("imgSectionImg", imgSectionImg2);

        await apiContenuImg.put('/2', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                entetes,
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setImgSectionImg2("");
            handleCloseupdImg2();
            allcontenuByImg2();
            setStatus(true);
            notify('success', `${res.data.message}`);
        })
    };
    const allcontenuByImg3 = async () => {
        const token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/3', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setTitleSectionImg3(res.data.data.titleSectionImg)
                setDescSectionImg3(res.data.data.descSectionImg)
                setImgSectionImg3(res.data.data.imgSectionImg)
                setImgSectionImg3upd(res.data.data.imgSectionImg)
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
            } 
        }
    };
    const updateContenuImage3 = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append("titleSectionImg", titleSectionImg3);
        formData.append("descSectionImg", descSectionImg3);
        formData.append("imgSectionImg", imgSectionImg3);

        await apiContenuImg.put('/3', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                entetes,
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setImgSectionImg3("");
            handleCloseupdImg3();
            allcontenuByImg3();
            setStatus(true);
            notify('success', `${res.data.message}`);
        })
    };
    const allcontenuByImg4 = async () => {
        const token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/4', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setTitleSectionImg4(res.data.data.titleSectionImg)
                setDescSectionImg4(res.data.data.descSectionImg)
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
            } 
        }
    };
    const updateContenuImage4 = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append("titleSectionImg", titleSectionImg4);
        formData.append("descSectionImg", descSectionImg4);

        await apiContenuImg.put('/4', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                entetes,
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setTitleSectionImg4("");
            setDescSectionImg4("");
            handleCloseupdImg4();
            allcontenuByImg4();
            setStatus(true);
            notify('success', `${res.data.message}`);
        })
    };
    const allcontenuByImg5 = async () => {
        const token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/5', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setTitleSectionImg5(res.data.data.titleSectionImg)
                setDescSectionImg5(res.data.data.descSectionImg)
                setImgSectionImg5(res.data.data.imgSectionImg)
                setImgSectionImg5upd(res.data.data.imgSectionImg)
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
            } 
        }
    };
    const updateContenuImage5 = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append("titleSectionImg", titleSectionImg5);
        formData.append("descSectionImg", descSectionImg5);
        formData.append("imgSectionImg", imgSectionImg5);

        await apiContenuImg.put('/5', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                entetes,
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setTitleSectionImg5("");
            setDescSectionImg5("");
            setImgSectionImg5("");
            handleCloseupdImg5();
            allcontenuByImg5();
            setStatus(true);
            notify('success', `${res.data.message}`);
        })
    };
    /* ***************************************** */

     /* ***************************************** */
    // Afficher tous les catégories
    const allcategorie = async () => {
        const token = localStorage.getItem('token');
        try {
            apiCategorie.get('/', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setCategorie(res.data.data);
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
            }  
        }
    };

    // Afficher tous les slides
    const allslide = async () => {
        const token = localStorage.getItem('token');
        try {
            apiContenuSlide.get('/', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setSlide(res.data.data);
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
            }  
        }
    };

    // Ajouter Slide
    const ajoutSlide = async (e) => {
        e.preventDefault();
        // e.persist();
        const token = localStorage.getItem('token');

        if (img_slide && categorieId) {
            // setIsLoading(true);
            try{
                const formData = new FormData();
                formData.append("img_slide", img_slide);
                formData.append("categorieID", categorieId);

                await apiContenuSlide.post('/', formData, {
                    headers: {
                        entetes,
                        Authorization: `Bearer ${token}`
                    }
                }).then (res => {
                    console.log(res.data);
                    setSlide([]);
                    allslide();
                    setStatus(true);
                    handleCloseaddSlide();
                    notify('success', `${res.data.message}`);
                }).catch((err) => {
                    console.log(err);
                    notify('warning', 'Vos informations sont incorrectes');
                  })
            } catch (err) {
                console.error(err);
                notify('', "Une erreur s'est produite lors de la création de l'article. Assurez-vous que les champs sont bien renseignés");
            }
        } else {
            notify('warning', 'Assurez-vous que les deux champs sont bien renseignés');
        }
    };

    // Supprimer un repas
    const deleteConfirm = (id) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Confirmer la suppression',

        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteSlide(id);
            } else if (result.isDenied) {
                Swal.fire('Suppression Annulée', '', 'info')
            }
        })
    };
    const deleteSlide = async (id) => {
        const token = localStorage.getItem('token');
        try {
            apiContenuSlide.delete(`/${id}`, {
                data: {id},
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                // alert(res.data)
                setSlide([]);
                allslide();
                notify('success', `${res.data.message}`);
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
            }  
        }
    }
      
    useEffect(() => {
        allslide();
        allcategorie();
    }, []);
    useEffect(() => {
        allapropos();
    }, [ids]);
    useEffect(() => {
        allcontenuByImg1();
    }, [1]);
    useEffect(() => {
        allcontenuByImg2();
    }, [2]);
    useEffect(() => {
        allcontenuByImg3();
    }, [3]);
    useEffect(() => {
        allcontenuByImg4();
    }, [4]);
    useEffect(() => {
        allcontenuByImg5();
    }, [5]);
      
    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Helmet>
                <title> Gestion des Utilisateurs | AGS </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Gestion du Contenu
                    </Typography>
                    <Button onClick={handleShowupd} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        Modifier le contenu
                    </Button>
                </Stack>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        mb: 5,
                        '& > :not(style)': {
                        width: '100%'
                        },
                    }}
                >
                    <Paper
                        sx={{
                            background: 'red',
                            borderBottomRightRadius: '50px',
                        }}
                        elevation={0}
                    >
                        <div  className='containerCardAbout'>
                            <div className='aboutLeft'>
                                <p className='titleApropos'>{title}</p>
                                <p>
                                    {description}
                                </p>
                                <br/>
                            </div>
                            <div className='aboutRight'>
                                <div className='about'>
                                    <p>Informations de constact</p>
                                    <img src={Logo} alt='img' width="262px" height="112px"/>
                                    <span>E-mail</span>
                                    <p>{email}</p>
                                    <span>Numéro téléphonique/Whatsapp</span>
                                    <p>{tel} </p>
                                </div>
                            </div>
                        </div>
                    </Paper>
                    {/* MODAL ADD A PROPOS */}
                    <Modal
                        open={showupd}
                        onClose={handleCloseupd}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div onClick={handleCloseupd}>
                                <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
                            </div>
                            <br/>
                            <Stack spacing={3}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Modifier le contenu
                                </Typography>
                                
                                <TextField
                                    name="title"
                                    type='text'
                                    label="Titre"
                                    variant="standard"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                                <TextField
                                    name="email"
                                    type='text'
                                    label="Email"
                                    variant="standard"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                {
                                    telValues.map((value, index) => (
                                      <TextField
                                        key={index}
                                        name={`tel-${index}`}
                                        type="text"
                                        label="Tel"
                                        variant="standard"
                                        value={value}
                                        onChange={(e) => {
                                          const updatedValues = [...telValues];
                                          updatedValues[index] = e.target.value;
                                          setTelValues(updatedValues);
                                        }}
                                        required
                                        multiple
                                      />
                                    ))
                                }
                                <Button variant="contained" onClick={handleCreationObjet}>
                                    Créer un nouvel objet
                                </Button>

                                <TextField
                                    name="description"
                                    type="text"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                    variant="standard"
                                />
                                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateApropos}>
                                    Modifier
                                </LoadingButton>
                            </Stack>
                        </Box>
                    </Modal>
                </Box>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Contenu Images
                    </Typography>
                </Stack>

                {/* CONTENU START */}
                <div className='containerCardMeubleAccUpd'>
                    <Card1
                        upd = {() => handleShowupdImg()}
                        images={imgSectionImgupd ?? "produit.jpg"}
                        title = {titleSectionImg}
                        desc = {descSectionImg}
                    />
                    <Card2
                        upd = {() => handleShowupdImg2()}
                        images={imgSectionImg2upd ?? "produit.jpg"}
                    />
                    <Card3
                        upd = {() => handleShowupdImg3()}
                        images={imgSectionImg3upd ?? "produit.jpg"}
                        title = {titleSectionImg3}
                        desc = {descSectionImg3}
                    />
                    <CardListMeuble
                        upd = {() => handleShowupdImg4()}
                        title = {titleSectionImg4}
                        desc = {descSectionImg4}
                    />
                    <CardH1
                        upd = {() => handleShowupdImg5()}
                        images={imgSectionImg5upd ?? "produit.jpg"}
                        title = {titleSectionImg5}
                        desc = {descSectionImg5}
                    />
                </div>

                <Modal
                    open={showupdImg}
                    onClose={handleCloseupdImg}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div onClick={handleCloseupdImg}>
                            <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
                        </div>
                        <br/>
                        <Stack spacing={3}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Modifier le contenu
                            </Typography>
                            
                            <TextField
                                name="title"
                                type='text'
                                label="Titre"
                                variant="standard"
                                value={titleSectionImg}
                                onChange={(e) => setTitleSectionImg(e.target.value)}
                                required
                            />
                            <TextField
                                name="descSectionImg"
                                type="text"
                                label="Description"
                                multiline
                                rows={4}
                                value={descSectionImg}
                                onChange={(e) => setDescSectionImg(e.target.value)}
                                required
                                variant="standard"
                            />
                            <TextField
                                name="imgSectionImg"
                                type='file'
                                label="Image"
                                variant="standard"
                                onChange={(e) => {
                                    setImgSectionImg(e.target.files[0]);
                                }} 
                                required
                            />
                            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateContenuImage}>
                                Modifier
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Modal>

                <Modal
                    open={showupdImg2}
                    onClose={handleCloseupdImg2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div onClick={handleCloseupdImg2}>
                            <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
                        </div>
                        <br/>
                        <Stack spacing={3}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Modifier le contenu des Images
                            </Typography>
                            
                            <TextField
                                name="imgSectionImg2"
                                type='file'
                                label="Image"
                                variant="standard"
                                onChange={(e) => {
                                    setImgSectionImg2(e.target.files[0]);
                                }} 
                                required
                            />
                            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateContenuImage2}>
                                Modifier
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Modal>

                <Modal
                    open={showupdImg3}
                    onClose={handleCloseupdImg3}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div onClick={handleCloseupdImg3}>
                            <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
                        </div>
                        <br/>
                        <Stack spacing={3}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Modifier le contenu des Images
                            </Typography>
                            
                            <TextField
                                name="titleSectionImg3"
                                type='text'
                                label="Titre"
                                variant="standard"
                                value={titleSectionImg3}
                                onChange={(e) => setTitleSectionImg3(e.target.value)}
                                required
                            />
                            <TextField
                                name="descSectionImg"
                                type="text"
                                label="Description"
                                multiline
                                rows={4}
                                value={descSectionImg3}
                                onChange={(e) => setDescSectionImg3(e.target.value)}
                                required
                                variant="standard"
                            />
                            <TextField
                                name="imgSectionImg"
                                type='file'
                                label="Image"
                                variant="standard"
                                onChange={(e) => {
                                    setImgSectionImg3(e.target.files[0]);
                                }} 
                                required
                            />
                            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateContenuImage3}>
                                Modifier
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Modal>

                <Modal
                    open={showupdImg4}
                    onClose={handleCloseupdImg4}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div onClick={handleCloseupdImg4}>
                            <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
                        </div>
                        <br/>
                        <Stack spacing={3}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Modifier le contenu des Images
                            </Typography>
                            
                            <TextField
                                name="titleSectionImg3"
                                type='text'
                                label="Titre"
                                variant="standard"
                                value={titleSectionImg4}
                                onChange={(e) => setTitleSectionImg4(e.target.value)}
                                required
                            />
                            <TextField
                                name="descSectionImg4"
                                type="text"
                                label="Description"
                                multiline
                                rows={4}
                                value={descSectionImg4}
                                onChange={(e) => setDescSectionImg4(e.target.value)}
                                required
                                variant="standard"
                            />
                            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateContenuImage4}>
                                Modifier
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Modal>

                <Modal
                    open={showupdImg5}
                    onClose={handleCloseupdImg5}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div onClick={handleCloseupdImg5}>
                            <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
                        </div>
                        <br/>
                        <Stack spacing={3}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Modifier le contenu des Images
                            </Typography>
                            
                            <TextField
                                name="titleSectionImg5"
                                type='text'
                                label="Titre"
                                variant="standard"
                                value={titleSectionImg5}
                                onChange={(e) => setTitleSectionImg5(e.target.value)}
                                required
                            />
                            <TextField
                                name="descSectionImg"
                                type="text"
                                label="Description"
                                multiline
                                rows={4}
                                value={descSectionImg5}
                                onChange={(e) => setDescSectionImg5(e.target.value)}
                                required
                                variant="standard"
                            />
                            <TextField
                                name="imgSectionImg5"
                                type='file'
                                label="Image"
                                variant="standard"
                                onChange={(e) => {
                                    setImgSectionImg5(e.target.files[0]);
                                }} 
                                required
                            />
                            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateContenuImage5}>
                                Modifier
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Modal>
                {/* CONTENU END */}

                {/* SLIDES START */}
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Contenu Slide
                    </Typography>
                    <Button onClick={handleShowaddSlide} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        Ajouter un nouveau slide
                    </Button>
                </Stack>

                <div className='containerCardMeubleAccUpd'>
                    <div style={{width: "100%", border: "1px solid black", display: "flex", flexDirection: "column", gap: "24px", height: "800px", overflowY: "scroll", marginBottom: "20px"}}>
                        {
                            slide?
                                <>
                                {slide.length !== 0 ? (
                                    slide.map((item) => (
                                        <div style={{width: "100%"}}>
                                            {slide ? (
                                                <div className="heightSlide" >
                                                    <img src={`${urlLocal}/image/slide/${item.img_slide}` }  alt='img' width="100%" height="100%" style={{position: "absolute",}}/>
                                                    <div style={{position: "absolute", border: "2px solid red", zIndex: 3, width: "100%", height: "100%", display: 'flex', alignItems: "flex-start", justifyContent: "flex-end", paddingTop: "20px", paddingRight: "20px" }}>
                                                        <Link 
                                                            className='btnAddPanierListe'
                                                            style={{width: "100px", height: "40px", color: "#FFFFFF", display: "flex", alignItems: "center", justifyContent: "center"}}
                                                            onClick={() => deleteConfirm(item.id)}
                                                        >
                                                            Supprimer
                                                        </Link>
                                                    </div>
                                                </div>
                                                ) : (
                                                <img src={ProduitEmpty} alt='default img' width="200px" height="200px"/>
                                                )
                                            }
                                        </div>
                                    ))
                                ) : (

                                    <div style={{width: "100%"}}>
                                        <h3 style={{fontSize: "20px", textAlign: "center"}}>Pas de slide</h3>
                                    </div>
                                )}
                                </>
                            :
                            <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px", padding: "10px 0px" }}>
                                <div className="center loaderpanel">
                                    <div className="ring" style={{width: "500px", height: "500px"}}></div>
                                </div>  
                            </div>
                        }
                    </div>
                    {/* SLIDES END */}
                    <Modal
                        open={showaddSlide}
                        onClose={handleCloseaddSlide}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div onClick={handleCloseaddSlide}>
                                <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
                            </div>
                            <br/>
                            <Stack spacing={3}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Ajouter un Slide
                                </Typography>

                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categorieId} 
                                        onChange={(e) => setCategorieId(e.target.value)} 
                                        required 
                                        label="Catégorie"
                                    >
                                        <MenuItem value="0">
                                            <em>Choisir une catégorie</em>
                                        </MenuItem>
                                        {
                                            categorie.map((item, index) =>(
                                                <MenuItem key={index} value={item.id}>{item.nomcat}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>

                                <TextField
                                    name="img_slide"
                                    type='file'
                                    onChange={(e) => {
                                        setImg_slide(e.target.files[0]);
                                        setuserInfo({ 
                                            ...userInfo, 
                                            filepreview: URL.createObjectURL(e.target.files[0]) 
                                        });
                                    }} 
                                    required
                                />
                                {userInfo.filepreview !== null &&
                                    <div className='sectionViewImage' style={{width: "100%"}}>
                                        <div style={{width: "100%", borderRadius: "10px", overflow: 'hidden', height: "100%"}}>
                                            <img src={userInfo.filepreview} alt='img' width="100%" height="100%"/>
                                        </div>
                                    </div>
                                }
                                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={ajoutSlide}>
                                    Ajouter
                                </LoadingButton>
                            </Stack>
                        </Box>
                    </Modal> 
                </div>
            
            </Container>
        </>
    )
}

import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Modal,
  TextField,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import { Badge } from 'rsuite';
// components
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import Label from '../components/label';
import Iconify from '../components/iconify';
import { AiOutlineClose } from 'react-icons/ai';
import Scrollbar from '../components/scrollbar';
import { apiLivraison, entete, notify, apiGestionPaiementMenu } from '../components/API_URL/ApiUrl';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const TABLE_HEAD = [
  { id: 'option', label: 'Option de paiement', alignRight: false },
  { id: 'etat', label: 'Etat', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.option.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function GestionPaiementPage() {
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('nomcat');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Modale ADD Categorie
  const [openModal, setOpenModal] = useState(null);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [paiementDispo, setPaiementDispo] = useState([]);
  const [option, setOption] = useState('');
  const [etat, setEtat] = useState('');
  const [ids, setIds] = useState(0);
  const [idUpd, setIdUpd] = useState(''); 
  const [paiementDispoID, setPaiementDispoID] = useState(''); 

  // Afficher tous les Paiements disponible
  const allPaiementMenu = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await apiGestionPaiementMenu.get('/', {
            headers: {
                entete,
                Authorization: `Bearer ${token}`
            }
        })
        setPaiementDispo(response.data.data);
        // console.log(menupaiement[0].option);
        // console.log("object:::" + infolivraison[0].userID +"=="+ id + ' ggffact:: '+ infolivraison[0].factureID )

    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  const getDataById = async () => {
    const token = localStorage.getItem('token');

    try {
        const { data } = await apiGestionPaiementMenu.get(`/${ids}`, {
            headers: {
                entete,
                Authorization: `Bearer ${token}`
            }
        })
        console.log(data);
        setPaiementDispoID(data.data.id);
        setIdUpd(data.data.id);
        setOption(data.data.option);
        setEtat(data.data.etat);
    } catch (error) { 
        if (error.response && error.response.status === 404) {
            // Gérez spécifiquement l'erreur 404
            // Affichez un message d'erreur ou effectuez d'autres actions
        } else {
            // Gérez les autres erreurs
            // Affichez un message d'erreur générique ou effectuez d'autres actions
        }
    }
}

  // Update de categorie
  const updateGestionPaiementMenu = async (e) => {
    // alert(ids)
    e.preventDefault();
    const token = localStorage.getItem('token');
    
    await apiGestionPaiementMenu.put(`/${ids}`, 
    { 
      option,
      etat
    },
    {
        headers: {
            entete,
            Authorization: `Bearer ${token}`
        }
    }).then(res => {
        notify('success', 'Mise à jour réussi');
        setOption("");
        setEtat("");
        setPaiementDispo([]);
        allPaiementMenu();
        setStatus(true);
        handleCloseModal();
    }).catch((err) => {
        console.log(err);
        notify('warning', "Echec de la mise à jour");
        handleCloseModal();
    });
};

const handleOpenMenu = (event, id) => {
  setOpen(event.currentTarget);
};

const handleCloseMenu = () => {
  setOpen(null);
};

const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

const handleSelectAllClick = (event) => {
  if (event.target.checked) {
  const newSelecteds = paiementDispo.map((n) => n.nomcat);
      setSelected(newSelecteds);
      return;
  }
  setSelected([]);
};

const handleClick = (event, option) => {
  const selectedIndex = selected.indexOf(option);
  let newSelected = [];
  if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, option);
  } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  }
  setSelected(newSelected);
};

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setPage(0);
  setRowsPerPage(parseInt(event.target.value, 10));
};

const handleFilterByName = (event) => {
  setPage(0);
  setFilterName(event.target.value);
};

const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - paiementDispo.length) : 0;

const filteredUsers = applySortFilter(paiementDispo, getComparator(order, orderBy), filterName);

const isNotFound = !filteredUsers.length && !!filterName;

const rowsPerPageOptions = [5, 10, 25];

for (let i = 25; i <= paiementDispo.length; i += 50) {
  rowsPerPageOptions.push(i);
}

useEffect(() => {
  allPaiementMenu();
}, []);

useEffect(() => {
  getDataById();
}, [ids]);


  return (
    <>
    <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
      <Helmet>
        <title> Gestion Des Paiements | AGS </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
                Gestion de Paiement
            </Typography>
        </Stack>

        <Card>
          <UserListToolbar label={"Rechercher un type de paiement"} numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                    <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={paiementDispo.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const { id, option, etat } = row;
                            const selectedUser = selected.indexOf(option) !== -1;

                            return (
                                <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                    <TableCell padding="checkbox">
                                        <Box height={16} />
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none">
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography variant="subtitle2" noWrap>
                                                {option}
                                            </Typography>
                                        </Stack>
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none">
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography variant="subtitle2" noWrap>
                                                <Label color={(etat === 'activer' && 'success') || 'error'}>{sentenceCase(etat)}</Label>
                                            </Typography>
                                        </Stack>
                                    </TableCell>

                                    <TableCell align="right">
                                      <MenuItem onClick={(e) => { handleOpenModal(e, id); setIds(id) }}>
                                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                        Modifier
                                      </MenuItem>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>

                    {isNotFound && (
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                    <Paper
                                        sx={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography variant="h6" paragraph>
                                            Pas trouvé
                                        </Typography>

                                        <Typography variant="body2">
                                            Aucun résultat trouvé pour &nbsp;
                                            <strong>&quot;{filterName}&quot;</strong>.
                                            <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                                        </Typography>
                                    </Paper>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={paiementDispo.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {/* MODAL UPDATE PAIEMENT */}
      <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div onClick={handleCloseModal}>
              <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
            </div>
            <br/>
            <Stack spacing={3}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Modifier l'etat du mode de paiement
                </Typography>
                <TextField
                    name="nomcat"
                    type='text'
                    label="Nom option"
                    value={option}
                    onChange={(e) => setOption(e.target.value)}
                    required
                    disabled
                />

                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Etat</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={etat} 
                    onChange={(e) => setEtat(e.target.value)} 
                    label="Etat"
                    required
                >
                  <MenuItem value={paiementDispoID}>{etat}</MenuItem>
                    <MenuItem value="activer">
                      <em>Activer</em>
                    </MenuItem>
                    <MenuItem value="desactiver">
                      <em>Désactiver</em>
                    </MenuItem>
                    
                </Select>
            </FormControl>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateGestionPaiementMenu}>
                    Modifier
                </LoadingButton>
            </Stack>
        </Box>
      </Modal>
    </>
  )
}

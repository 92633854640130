import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
// @mui
import { Box, Card, Link, Typography, Stack, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import { urlLocal } from '../../../components/API_URL/ApiUrl';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  article: PropTypes.object,
};

export default function ShopProductCard({ article, onClickUpdate, onClickDelete, onClickStock }) {
  const { id_art, nomarticle, img_art, prix, description, etat, prixPromo } = article;

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg alt={nomarticle} src={`${urlLocal}/image/${img_art}`} />
      </Box>

      <Stack spacing={1} sx={{ p: 2 }}>
        <Link color="inherit" underline="none" display="flex" flexWrap="wrap-reverse" alignItems="center" justifyContent="space-between" >
          <Typography variant="subtitle2" noWrap>
            {nomarticle}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            <Label color={(article.Stock_Art.quantiteStock <= 10 && 'error') || 'success'}>{sentenceCase(article.Stock_Art.quantiteStock)} Stock</Label>
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
         <ColorPreview descriptions={description} />
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {prixPromo && fCurrency(prixPromo)}
            </Typography>
            &nbsp;
            {fCurrency(prix)} F CFA
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <IconButton aria-label="update" onClick={() => onClickUpdate(article.id_art)}>
            <Iconify icon={'eva:edit-fill'} />
          </IconButton>
          <IconButton onClick={() => onClickDelete(article.id_art)} style={{display: 'flex', alignItems: "center", justifyContent: "center"}} sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} />
          </IconButton>
          <IconButton onClick={() => onClickStock(article.id_art)} aria-label="stock">
            <Iconify icon="eva:plus-fill" />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
}

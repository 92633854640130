import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Modal,
  TextField,
  FormControl,
  Select,
  InputLabel
} from '@mui/material';
// components
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import Label from '../components/label';
import Iconify from '../components/iconify';
import { AiOutlineClose } from 'react-icons/ai';
import Scrollbar from '../components/scrollbar';
import { apiCategorie, apiSousCat, entete, notify } from '../components/API_URL/ApiUrl';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const TABLE_HEAD = [
    { id: 'libsouscat', label: 'Sous Categorie', alignRight: false },
    { id: 'Categorie.nomcat', label: 'Nom Categorie', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.libsouscat.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function GestionSousCategoriePage() {
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('nomcat');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Modale ADD Sous Categorie
    const [openModal, setOpenModal] = useState(null);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    // Modal Update Sous Categorie
    const [showupd, setShowupd] = useState(false);
    const handleCloseupd = () => {
        setShowupd(false)
    };
    const handleShowupd = (e) => {
        setShowupd(true)
    };

    const [categorie, setCategorie] = useState([]);
    const [sousCat, setSousCat] = useState([]);
    const [ids, setIds] = useState(0);
    const [libsouscat, setLibsouscat] = useState('');
    const [categoriId, setCategoriId] = useState('');
    const [idUpd, setIdUpd] = useState('');

    // All Categorie
    const allcategorie = async () => {
        const token = localStorage.getItem('token');
        try {
            apiCategorie.get('/', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setCategorie(res.data.data);
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    };

    // ALl Sous Categorie
    const allsouscategorie = async () => {
        const token = localStorage.getItem('token');
        try {
            apiSousCat.get('/', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setSousCat(res.data.data);
            })
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    };

    const getDataById = async () => {
        const token = localStorage.getItem('token');
        try {
            const { data } = await apiSousCat.get(`/${ids}`, {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(data);
            setIdUpd(data.data.id)
            setLibsouscat(data.data.libsouscat)
            setCategoriId(data.data.categoriId)
        } catch (error) { 
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    }

    // Add sous Categorie
    const ajoutSousCategorie = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        // alert(nomSouscat);
    
        if (libsouscat && categoriId) {
            try{
                // alert(libsouscat)
                await apiSousCat.post('/', 
                { libsouscat, categoriId }, {
                    headers: {
                        entete,
                        Authorization: `Bearer ${token}`
                    }
                }).then(res => {
                    console.log(res.data)
                    // alert(res.data.message)
                    setLibsouscat("");
                    setCategoriId("");
                    handleCloseModal();
                    setLibsouscat([]);
                    allsouscategorie();
                    setStatus(true);
                    notify('success', `${res.data.message}`);
                }).catch((err) => {
                    console.log(err);
                    if (err.response && err.response.data && err.response.data.message === "La sous-catégorie existe déjà.") {
                      notify('warning', "La sous-catégorie existe déjà.");
                    } else {
                      notify('warning', "Une erreur s'est produite lors de la sous-création de la catégorie. Assurez-vous que les champs sont bien renseignés");
                    }
                    setLibsouscat("");
                    setCategoriId("");
                    handleCloseModal();
                    setLibsouscat([]);
                    allsouscategorie();
                    setStatus(true);
                })
            } catch (err) {
                console.error(err);
                notify('', `Echec lors de l'ajout de la sous catégorie. Veuillez rééssayer plutard`)
                handleCloseModal();
            }
        } else {
            notify('', `Vérifiez que tous les champs ne sont pas vide`);
        }
    };

    // Update Sius Categorie
    const updateSousCategorie = async (e) => {
        // alert(ids)
        e.preventDefault();
        const token = localStorage.getItem('token');
        try{ 
            await apiSousCat.put(`/${ids}`, 
            { 
                libsouscat,
                categoriId
            },
            {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                handleCloseupd();
                setLibsouscat("");
                setSousCat([]);
                allsouscategorie();
                setStatus(true);
                notify('success', 'Mise à jour réussi');
            }).catch((err) => {
                console.log(err);
                notify('warning', "Echec de la mise à jour");
                handleCloseupd();
            });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    };

    // Delete sous categorie
    const deleteConfirm = (ids) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Confirmer la suppression',
            denyButtonText: 'Non',

        }).then((result) => {
            // Read more about isConfirmed, isDenied below 
            if (result.isConfirmed) {
                deleteArticle(ids);
            } else if (result.isDenied) {
                Swal.fire('Suppression Annulée', '', 'info')
            }
        })
    };
    const deleteArticle = async (ids) => {
        const token = localStorage.getItem('token');
        try{ 
            apiSousCat.delete(`/${ids}`, {
                id: ids,
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                // alert(res.data)
                notify('success', 'Suppression réussi');
                setSousCat([]);
                allcategorie();
                allsouscategorie();
            }).catch((err) => {
                console.log(err);
                notify('warning', "Suppression échoué");
                handleCloseModal();
            })
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    }

    const handleOpenMenu = (event, id) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = sousCat.map((n) => n.libsouscat);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, libsouscat) => {
        const selectedIndex = selected.indexOf(libsouscat);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, libsouscat);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sousCat.length) : 0;

    const filteredUsers = applySortFilter(sousCat, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    const rowsPerPageOptions = [5, 10, 25];

    for (let i = 25; i <= sousCat.length; i += 50) {
        rowsPerPageOptions.push(i);
    }

    useEffect(() => {
        allsouscategorie();
        allcategorie();
    }, []);

    useEffect(() => {
        getDataById();
    }, [ids]);


    return (
        <>
        <Helmet>
            <title> Gestion Des Sous-Categories | AGS </title>
        </Helmet>

        <Container>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Gestion de Sous-catégories
                </Typography>
                <Button onClick={handleOpenModal} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                    Ajouter une sous-catégorie
                </Button>
            </Stack>

            <Card>
                <UserListToolbar label={"Rechercher une sous-catégorie"} numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={sousCat.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { id, libsouscat, Categorie } = row;
                                    const selectedUser = selected.indexOf(libsouscat) !== -1;

                                    return (
                                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                            <TableCell padding="checkbox">
                                                <Box height={16} />
                                            </TableCell>

                                            <TableCell component="th" scope="row" padding="none">
                                                <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                                    <Typography variant="subtitle2" noWrap>
                                                        {libsouscat}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>

                                            <TableCell component="th" scope="row" padding="none">
                                                <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                                    <Typography variant="subtitle2" noWrap>
                                                        {Categorie && Categorie.nomcat ? Categorie.nomcat : ''}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>

                                            <TableCell align="right">
                                                <IconButton size="large" color="inherit" onClick={(event) => { handleOpenMenu(event, id); setIds(id) }}>
                                                    <Iconify icon={'eva:more-vertical-fill'} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>

                            {isNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <Paper
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography variant="h6" paragraph>
                                                    Pas trouvé
                                                </Typography>

                                                <Typography variant="body2">
                                                    Aucun résultat trouvé pour &nbsp;
                                                    <strong>&quot;{filterName}&quot;</strong>.
                                                    <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                                                </Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={sousCat.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
        </Container>

        <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 140,
                    '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                    },
                },
            }}
        >
            <MenuItem onClick={() => { handleShowupd(); handleCloseMenu()}}>
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                Modifier
            </MenuItem>

            <MenuItem onClick={() => { deleteConfirm(ids); handleCloseMenu() }} sx={{ color: 'error.main' }}>
                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                Supprimer
            </MenuItem>
        </Popover>

        {/* MODAL ADD SOUS-CATEGORIE */}
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div onClick={handleCloseModal}>
                    <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
                </div>
                <br/>
                <Stack spacing={3}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Ajouter un Sous Catégorie
                    </Typography>

                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={categoriId} 
                            onChange={(e) => setCategoriId(e.target.value)} 
                            label="Catégorie"
                            required
                        >
                            <MenuItem value="0">
                                <em>Choisir une catégorie</em>
                            </MenuItem>
                            {
                                categorie.map((item, index) =>(
                                    <MenuItem key={index} value={item.id}>{item.nomcat}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <TextField
                        name="libsouscat"
                        type='text'
                        label="Nom Sous catégorie"
                        value={libsouscat}
                        onChange={(e) => setLibsouscat(e.target.value)}
                        required
                        autoFocus
                    />
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={ajoutSousCategorie}>
                        Ajouter
                    </LoadingButton>
                </Stack>
            </Box>
        </Modal> 

        {/* MODAL UPDATE SOUS-CATEGORIE */}
        <Modal
            open={showupd}
            onClose={handleCloseupd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div onClick={handleCloseupd}>
                    <AiOutlineClose size={25} style={{cursor: "pointer", float: "right"}}/>
                </div>
                <br/>
                <Stack spacing={3}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Modifier un Sous Catégorie
                    </Typography>

                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={categoriId} 
                            onChange={(e) => setCategoriId(e.target.value)} 
                            label="Catégorie"
                            required
                        >
                            <MenuItem value="0">
                                <em>Choisir une catégorie</em>
                            </MenuItem>
                            {
                                categorie.map((item, index) =>(
                                    <MenuItem key={index} value={item.id}>{item.nomcat}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <TextField
                        name="libsouscat"
                        type='text'
                        label="Nom Sous catégorie"
                        value={libsouscat}
                        onChange={(e) => setLibsouscat(e.target.value)}
                        required
                    />

                    <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateSousCategorie}>
                        Modifier
                    </LoadingButton>
                </Stack>
            </Box>
        </Modal> 
    </>
    )
}

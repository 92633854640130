import { Helmet } from 'react-helmet-async';
import React,{ useState, useEffect } from 'react';
// @mui
import {
  Stack,
  Container,
  Typography,
  Tabs,
  Tab,
  Box
} from '@mui/material';
import { Badge } from 'rsuite';
// components
import { apiLivraison, entete } from '../components/API_URL/ApiUrl';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function GestionCommandePage() {
    // ----------------------------------------
    const [value, setValue] = React.useState('one');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // ----------------------------------------
    const id = localStorage.getItem('id');
    const [search, setSearch] = useState("");
    const [livraison, setLivraison] = useState([]);
    const [livraisonValider, setLivraisonValider] = useState([]);
    const [infolivraison, setInfoLivraison] = useState([]);
    const [userID, setUserID] = useState("");
    const [panierInfoSupID, setPanierInfoSupID] = useState(null);
    const [etat, setEtat] = useState("");

    // Afficher tous les livraisons
    const allLivraisonEnCours = async () => {
        try {
        const response = await apiLivraison.get(`/`, {
            headers: {
                entete
            }
        });
    
        // Mapper et associer etat_fact et etat_liv ensemble
        const etats = response.data.livraison.filter(item => item.etat_liv === "En attente" && item.Facture.etat_fact === "Valider").map(item => ({
            etat_liv: item.etat_liv,
            factureID: item.factureID,
            etat_fact: item.Facture.etat_fact,
            ref_facture: item.Facture.ref_facture,
            nomcomplet: item.Facture.nomcomplet,
            numero: item.Facture.numero,
            email: item.Facture.email,
            panierinfosupID: item.Facture.panierinfosupID,
            date: item.Facture.date,
            userID: item.Facture.userID,
        }));
        setInfoLivraison(etats);
        // console.log("etatsss:" + etats);
        console.log(infolivraison);
        // console.log("object:::" + infolivraison[0].userID +"=="+ id + ' ggffact:: '+ infolivraison[0].factureID )
    
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    };

    const allLivraisonValider = async () => {
        try {
        const response = await apiLivraison.get(`/`, {
            headers: {
                entete
            }
        });
    
        // Mapper et associer etat_fact et etat_liv ensemble
        const etats = response.data.livraison.filter(item => item.etat_liv === "Valider" && item.Facture.etat_fact === "Valider").map(item => ({
            etat_liv: item.etat_liv,
            factureID: item.factureID,
            etat_fact: item.Facture.etat_fact,
            ref_facture: item.Facture.ref_facture,
            nomcomplet: item.Facture.nomcomplet,
            numero: item.Facture.numero,
            email: item.Facture.email,
            panierinfosupID: item.Facture.panierinfosupID,
            date: item.Facture.date,
            userID: item.Facture.userID,
        }));
        setLivraisonValider(etats);
        // console.log("etatsss:" + etats);
        console.log(infolivraison);
        // console.log("object:::" + infolivraison[0].userID +"=="+ id + ' ggffact:: '+ infolivraison[0].factureID )
    
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
            }
        }
    };

    const gradientStyle = {
        // background: 'linear-gradient(to right, #00b09b, #96c93d)',
        background: 'linear-gradient(to right, #ff4e50, #f9d423)',
        color: '#fff',
        padding: '2px 10px',
    };
    const gradientStyleSuccess = {
        background: 'linear-gradient(to right, #00b09b, #96c93d)',
        color: '#fff',
        padding: '2px 10px',
    };

    useEffect(() => {
        allLivraisonValider();
        allLivraisonEnCours();
    }, []);

    return (
        <>
            <Helmet>
                <title> Gestion Des Commandes | AGS </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Gestion de commandes
                    </Typography>
                </Stack>

                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="one" label="Gestion de commandes en cours" />
                        <Tab value="two" label="Gestion de commandes Valider" />
                    </Tabs>
                </Box>

                {value === 'one' && (
                    <>
                        <br/>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <UserListToolbar label={"Rechercher une commande en cours"} />
                            <Typography variant="h4" gutterBottom>
                                Gestion de commandes en cours
                            </Typography>
                        </Stack>
                        <div style={{width: "100%"}}>
                            {
                                infolivraison !== 0?
                                <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                    {
                                        infolivraison.map(item => (
                                            <div className='containerLivraison'>
                                                <div style={{width: "100%", textAlign: "center"}}>
                                                    <h3>{item.ref_facture}</h3>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <span>{item.nomcomplet}</span>
                                                        <span>{item.numero}</span>
                                                        <span>{item.email}</span>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <Badge
                                                            style={item.etat_fact === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                            content={item.etat_fact === "En attente" ? "Facture non validée" : "Facture validée"}
                                                        />
                                                        <br/>
                                                        <Badge
                                                            style={item.etat_liv === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                            content={item.etat_liv === "En attente" ? "Livraison non validée" : "Livraison validée"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                <div style={{width: "100%", textAlign: "center"}}>
                                    Pas de commande en cours de validation
                                </div>
                            }
                        </div>
                    </>
                )}

                {value === 'two' && (
                    <>
                        <br/>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <UserListToolbar label={"Rechercher une commande valider"} />
                            <Typography variant="h4" gutterBottom>
                                Gestion de commandes Valider
                            </Typography>
                        </Stack>
                        <div style={{width: "100%"}}> 
                            {
                                livraisonValider !== 0?
                                <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                    {
                                        livraisonValider.map(item => (
                                            <div className='containerLivraison'>
                                                <div style={{width: "100%", textAlign: "center"}}>
                                                    <h3>{item.ref_facture}</h3>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <span>{item.nomcomplet}</span>
                                                        <span>{item.numero}</span>
                                                        <span>{item.email}</span>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <Badge
                                                            style={item.etat_fact === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                            content={item.etat_fact === "En attente" ? "Facture non validée" : "Facture validée"}
                                                        />
                                                        <br/>
                                                        <Badge
                                                            style={item.etat_liv === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                            content={item.etat_liv === "En attente" ? "Livraison non validée" : "Livraison validée"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                <div style={{width: "100%", textAlign: "center"}}>
                                    La liste des commandes de validation n'est pas disponible
                                </div>
                            }
                        </div>
                    </>
                )}

            </Container>

        </>
    )
}

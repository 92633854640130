import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Typography, Stack } from '@mui/material';

// ----------------------------------------------------------------------

ColorPreview.propTypes = {
  sx: PropTypes.object,
  limit: PropTypes.number,
  descriptions: PropTypes.arrayOf(PropTypes.string),
};

export default function ColorPreview({ descriptions, limit = 10, sx }) {
  const showColor = descriptions.slice(0, limit);

  return (
    <Stack component="span" direction="row" alignItems="center" justifyContent="flex-end" sx={sx}>
      {
        descriptions.length === limit? <>{showColor}... </>: <>{showColor}</>
      }  
    </Stack>
  );
}
